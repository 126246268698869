import ButtonDon from '../../components/ButtonDon'

function Support() {
  return (
    <section className="container">
      <div className="wrap style-two">
        <h2>Soutenez notre cause</h2>
        <p>
          Votre soutien financier est essentiel pour nous aider à poursuivre notre lutte contre la
          <span> corruption active-passive, escroquerie, abus de confiance.</span> Chaque euro
          compte et contribue directement à renforcer notre capacité d&apos;action. Nous comptons
          sur votre générosité pour nous permettre de mener à bien nos projets visant à promouvoir
          l&apos;intégrité et l&apos;équité dans notre société.
        </p>
        <p>
          Les dons fait à notre association ouvrent droit à crédit d&apos;impôt pour les donateurs,
          qu&apos;ils soient des particuliers ou des professionnels.
        </p>
        <div className="container-article">
          <article>
            <h3>
              <span>Particulier</span>
            </h3>
            <p>
              En faisant un don à notre association vous bénéficiez d&apos;un crédit d&apos;impôt
              sur le revenu de 66% du montant de votre don, dans la limite de 20% de votre revenu
              imposable. Ainsi, votre générosité vous permet de soutenir nos actions.
            </p>
            <br />
            <p>
              • Reçu ( <span>CERFA N°11580*05</span> ) des dons et versements effectués par les
              particuliers au titre des articles 200 et 978 du code général des impôts.
            </p>
          </article>
          <article>
            <h3>
              <span>Entreprises (Professionnels)</span>
            </h3>
            <p>
              Les sociétés bénéficient également de crédit d&apos;impôt de 60% du montant de votre
              don, pris dans la limite de 20 000€ ou de 0,5% du chiffre d&apos;affaires HT lorsque
              ce dernier montant est plus élevé.
            </p>
            <p>
              C&apos;est une manière efficace pour les sociétés de soutenir une cause sociale et
              solidaire.
            </p>
            <br />
            <p>
              • Reçu ( <span>CERFA N°16216*02</span> ) des dons et versements effectués par les
              Entreprises au titre de l&apos;article 238 bis du code général des impôts.
            </p>
          </article>
        </div>
        <ButtonDon styleBtn="btn-don-presentation" />
      </div>
    </section>
  )
}

export default Support
